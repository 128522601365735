import React, { useState, useEffect } from 'react';

import Header from '../Common/Header';
import { FaTimes } from "react-icons/fa";
import '../App.css'
import MYA_Assets from '../assets/myaAssets.webp'
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function Categories() {
  const { categoryName } = useParams();
  useEffect(() => {
    const windowHeight = window.innerHeight;
    const offset = windowHeight * 0.1;
    window.scrollTo(0, offset);
    if (categoryName == "Models")
      document.getElementById('input-budget').placeholder = 'Your Budget? Prefered(350$ to 1000$)';
    if (categoryName == "Overlay")
      document.getElementById('input-budget').placeholder = 'Your Budget? Prefered(150$ to 350$)';
    if (categoryName == "Animation")
      document.getElementById('input-budget').placeholder = 'Your Budget? Prefered(150$ to 350$)';
    if (categoryName == "Rigging")
      document.getElementById('input-budget').placeholder = 'Your Budget? Prefered(350$ to 800$)';
    if (categoryName == "Banner")
      document.getElementById('input-budget').placeholder = 'Your Budget? Prefered(180$ to 450$)';
    if (categoryName == "Artworks")
      document.getElementById('input-budget').placeholder = 'Your Budget? Prefered(180$ to 450$)';
  }, [categoryName]);
  const [sentEmail, setSentEmail] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const handleButtonClick = () => {
    var colorThemeInput = document.getElementById('input-color-theme');
    var colorTheme = colorThemeInput.value;
    var conceptStyleInput = document.getElementById('input-concept');
    var conceptStyle = conceptStyleInput.value;
    var preferredDesignerInput = document.getElementById('prefered-designer');
    var preferredDesigner = preferredDesignerInput.value;
    if (colorTheme.length < 2 || conceptStyle.length < 2)
      return toast.error('Fill the details first please', {
        position: toast.POSITION.TOP_RIGHT
      });
    setShowPopup(true);
  };
  const handleCloseClick = () => {
    setShowPopup(false);
  };
  const isValueEmpty = (value) => {
    return value.trim() === "";
  };
  async function sendEmail() {
    if (sentEmail) {
      toast.success('Yes Yes, We Received your Email! We will reach you out', {
        position: toast.POSITION.TOP_RIGHT
      });
      return
    }
    const nameInput = document.getElementById("name").value;
    const twitterInput = document.getElementById("Twitter").value || "";
    const emailInput = document.getElementById("email").value;
    const locationInput = document.getElementById("Location").value || "";
    const debutDateInput = document.getElementById("debutdate").value || "";
    if (isValueEmpty(nameInput) || isValueEmpty(emailInput)) {
      return toast.error('Error: Missing or Invalid required input (*)', {
        position: toast.POSITION.TOP_RIGHT
      });
    }
    var colorThemeInput = document.getElementById('input-color-theme');
    var colorTheme = colorThemeInput.value;
    var conceptStyleInput = document.getElementById('input-concept');
    var conceptStyle = conceptStyleInput.value;
    var preferredDesignerInput = document.getElementById('prefered-designer') || "";
    var preferredDesigner = preferredDesignerInput.value;
    var BudgetInputElement = document.getElementById('input-budget') || "0";
    var BudgetInput = BudgetInputElement.value;
    const linkElement2 = document.getElementById('submitbtn');
    linkElement2.textContent = "Sending...";
    try {
      const response = await fetch('https://mystuber-vercel.vercel.app/email/emailCategory', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ password: 'foxyfoxy', toEmail: 'graphicalassets@gmail.com', subject: categoryName, colorTheme: colorTheme, conceptStyle: conceptStyle, preferredDesigner: preferredDesigner, name: nameInput, email: emailInput, twitterInput: twitterInput, locationInput: locationInput, debutDateInput: debutDateInput,BudgetInput:BudgetInput }),
      });
      const data = await response.status;
      if (data === 200 || data == 200) {
        setSentEmail(true);
        const linkElement2 = document.getElementById('submitbtn');
        linkElement2.textContent = "Request sent";
        toast.success('Request Sent Successfull! We will reach you out', {
          position: toast.POSITION.TOP_RIGHT
        });
      }
      else {
        linkElement2.textContent = "Submit Request";
        toast.error('Error: Server is busy. Please try again later', {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    } catch (error) {
      linkElement2.textContent = "Submit Request";
      console.error('Error:', error);
      toast.error('Error: Server is busy. Please try again later', {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  }
  return (
    <>
      <Header />
      <ToastContainer />
      <div className="containerCat">
        <div className="top-section">
        </div>
        <div className="content">
          <img src={MYA_Assets} />
        </div>
      </div>

      <section className="bg-section">

        <div className="container">
          <h2>How this Process <span style={{ color: 'red', fontWeight: 'bold' }}>Works?</span></h2>
          <p>
            All of your given details would be sent to the Official Team and it would be checked by them
          </p>
          <p>
            when they Approved all of your details you will recived a mail on your given email that your Order Details are all good to go
          </p>
          <p>
            if the given detalls are not clear you will recieve a different email asking simple Question in order to understand your Design Art Taste After you recieved the email stating that your Details are Good to go we will then proceed futher for the Upfront.
          </p>
          <h2>What will be the <span style={{ color: 'red', fontWeight: 'bold' }}>Payment Process?</span></h2>
          <p>
            In order to make our Clients feel more comfortable we do not demands the whole Payment right from the start but just an Upfront fee to book your Project Slot in our list So that we can start your Work and the rest of the payment you can send easily when you Approved your Desired Design! This is the Most Safest Process we Designed for Our Clients So that they can get their Dream Assets Without Being worry of getting Scammed!
          </p>
          <p>
            "Let's work together to bring your <span style={{ color: 'red', fontWeight: 'bold' }}> Vtuber vision to life.</span> "
          </p>
        </div>
      </section>
      <section className="bg-section2">
        <div className="container">
          <h2>Details of the <span style={{ color: 'red', fontWeight: 'bold' }}>{categoryName}</span></h2>
          <input id='input-color-theme' type="text" placeholder='Color theme which suits you the most?' required />
          <br />
          <textarea id='input-concept' type="text" placeholder='Concept Style, details you have in your mind (Reference Image would work aswell)' required />
          <br />
          <input id='prefered-designer' type="text" placeholder='Do you have a Prefered Designer You specifically want to work on your project? (optional)' required />
          <br />
          <input id='input-budget' type="text" placeholder='Your Budget?   Prefered(450$ to 1000$)' required />
          <br />
          {/* <button onClick={sendEmail}>Submit Request</button> */}
          <button onClick={handleButtonClick} >Submit Request</button>

        </div>
      </section>
      <section className='thirdsection'>
        <div className="enquiry-icons">
          <a href="mailto:graphicalassets@gmail.com">
            <div className="enquiry-icon purple-circle">
              <i className="fa fa-envelope"></i>
            </div>
          </a>
          graphicalassets@gmail.com
        </div>
      </section>
      <section className='thirdsection'>
        <div className="enquiry-icon purple-circle" style={{ "background": "white" }}>
          <a href='https://twitter.com/The_MYA_Assets' target="_blank" rel="noopener noreferrer">
            <i style={{ "color": "black" }} className="fa fa-twitter"></i>
          </a>
        </div>
        <div className="enquiry-icon purple-circle" style={{ "background": "white" }}>
          <a href="https://www.behance.net/myaassets1" target="_blank" rel="noopener noreferrer">
            <i style={{ color: 'black' }} className="fa fa-behance"></i>
          </a>
        </div>
      </section>
      <div>
        {showPopup && (
          <>
            <div className="backdrop"></div>
            <div className="popup-form">
              <button className="close-button" onClick={handleCloseClick}>
                <FaTimes />
              </button>
              <h2 style={{ "textAlign": "center" }}>Your Contact <span style={{ color: 'red', fontWeight: 'bolder' }}>Information</span></h2>
              <div className="input-group">
                <label htmlFor="name">Name*</label>
                <input type="text" id="name" name="name" required />
                <label htmlFor="name">Twitter Account</label>
                <input type="text" id="Twitter" name="name" required />
              </div>
              <div className="input-group">
                <label htmlFor="email">Email*</label>
                <input type="email" id="email" name="email" required />
              </div>
              <div className="input-group">
                <label htmlFor="location">Location</label>
                <input type="location" id="Location" name="location" required />
                <label htmlFor="name">debut date</label>
                <input type="date" id="debutdate" name="name" />
              </div>
              <button className='btn' onClick={sendEmail} id="submitbtn">Submit</button>
            </div>
          </>
        )}
        <footer>Copyrigths 2023 MyAtuberAssets</footer>
      </div>
    </>
  )
}

export default Categories;