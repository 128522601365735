import { useState } from 'react';
import '../App.css';
import banner from '../assets/Hero/banner.webp';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import aboutusimg from '../assets/formation_membres_ssct-min.webp';
import halfbutterfly from '../assets/Backgrounds/bg-pt-5.webp';
import mobilephone from '../assets/Backgrounds/bgp2.webp';
import banner2 from '../assets/Hero/Banner2.webp';
import overlayart from '../assets/Categories/overlay-art.webp';
import custommodel from '../assets/image_mapping.webp';
import redHoodie from '../assets/products/Cozyhoddie1.webp';
import Hoodie2 from '../assets/products/Cozyhoddie2.webp';
import Hoodie3 from '../assets/products/Cozyhoddie3.webp';
import Hoodie4 from '../assets/products/Cozyhoddie4.webp';
import Hoodie5 from '../assets/products/Cozyhoddie5.webp';
import Hoodie6 from '../assets/products/Cozyhoddie6.webp';
import Handholding from '../assets/products/handholding.webp';
import Holdingmug from '../assets/products/holdingmung1.webp';
import affiliateimg from '../assets/Backgrounds/affiliate.jpg';
import Logo from '../assets/logo.webp';
import Portfolio from '../assets/portfolio.webp';
import models from '../assets/Categories/models.webp';
import banners from '../assets/Categories/banner.webp';
import artwork from '../assets/Categories/artworks.webp';
import rigging from '../assets/Categories/rigging.webp';
import overrlay from '../assets/Categories/overlay.webp';
import animation from '../assets/Categories/animation.webp';
import male from '../assets/Users/male.webp';
import female from '../assets/Users/female.webp';
import topbg from '../assets/Backgrounds/topbg.webp';
import dotsimg from '../assets/Backgrounds/bg-pt4.webp';

import { FaTimes } from "react-icons/fa";
import Header from '../Common/Header';
import MYA_Assets from '../assets/myaAssets.webp';
import { Link, useNavigate } from 'react-router-dom';

function Home() {
    const navigate = useNavigate();
    const [sentEmail, setSentEmail] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [sentEmail2, setSentEmail2] = useState(false);
    const [showPopup2, setShowPopup2] = useState(false);
    const [selectedButton, setSelectedButton] = useState("");
    const handleButtonClick = (buttonId) => {
        setShowPopup(true);
        setSelectedButton(buttonId)
    };
    const handleCloseClick = () => {
        setShowPopup(false);
    };
    const handleButtonClick2 = (buttonId) => {
        setShowPopup2(true);
    };
    const handleCloseClick2 = () => {
        setShowPopup2(false);
    };
    const isValueEmpty = (value) => {
        return value.trim() === "";
    };
    const goToPackages = () => {
        const nameInput = document.getElementById("name");
        const twitterInput = document.getElementById("Twitter") || "";
        const emailInput = document.getElementById("email");
        const locationInput = document.getElementById("Location") || "";
        const debutDateInput = document.getElementById("debutdate") || "";
        if (isValueEmpty(nameInput.value) || isValueEmpty(emailInput.value) || !emailInput.value.includes('@')) {
            toast.error('Error: Name or Email is missing/Incorrect', {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
        navigate("/packages?packagename=" + selectedButton + "&name=" + nameInput.value + "&twitterInput=" + twitterInput.value + "&email=" + emailInput.value + "&locationInput=" + locationInput.value + "&debutDateInput=" + debutDateInput.value)
    };
    function generateRandomText(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
        for (var i = 0; i < length; i++) {
            var randomIndex = Math.floor(Math.random() * characters.length);
            result += characters.charAt(randomIndex);
        }

        return result;
    }

    const generateAffiliateLink = async () => {
        if (sentEmail2) {
            const linkElement = document.getElementById('generateAffiliateLink');
            const text = linkElement.textContent;
            const textarea = document.createElement('textarea');
            textarea.value = text;
            document.body.appendChild(textarea);
            textarea.select();
            document.execCommand('copy');
            document.body.removeChild(textarea);
            const linkElementCopied = document.getElementById('generateAffiliateLinkbtn');
            linkElementCopied.textContent = "Copied!";
            return
        }
        const nameInput = document.getElementById("name2").value;
        const twitterInput = document.getElementById("Twitter2").value;
        const emailInput = document.getElementById("email2").value;
        const locationInput = document.getElementById("Location2").value;
        let affiliatelink = window.location.href + generateRandomText(10);
        const linkElement2 = document.getElementById('generateAffiliateLinkbtn');
        linkElement2.textContent = "Loading...";
        try {
            const response = await fetch('https://mystuber-vercel.vercel.app/email/emailAffiliate', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    password: 'foxyfoxy',
                    toEmail: 'graphicalassets@gmail.com',
                    subject: "Affiliate",
                    name: nameInput,
                    email: emailInput,
                    twitter: twitterInput,
                    location: locationInput,
                    affiliatelink: affiliatelink
                }),
            });
            const data = await response.status;
            if (data === 200 || data == 200) {
                setSentEmail2(true);
                const linkElement = document.getElementById('generateAffiliateLink');
                linkElement.textContent = affiliatelink;
                linkElement2.textContent = "Click to copy Link below";
                toast.success('Request Sent Successfull! We will reach you out', {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            else {
                toast.error('Error: Server is busy. Please try again later', {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('Error: Server is busy. Please try again later', {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }
    const goToProducts = () => {
        navigate("/products");
    };
    async function sendEnquiryEmail() {
        if (sentEmail) {
            toast.success('Yes Yes, We Received your Email! We will reach you out', {
                position: toast.POSITION.TOP_RIGHT
            });
            return
        }
        const lastName = document.getElementById('lastname').value;
        const firstName = document.getElementById('firstname').value;
        const email = document.getElementById('emailenquiry').value;
        const number = document.getElementById('numberenquiry').value;
        const location = document.getElementById('locationenquiry').value || "";
        const artistName = document.getElementById('artistenquiry').value || "";
        const topics = document.getElementById('topicsenquiry').value;
        const details = document.getElementById('detailsenquiry').value;
        const linkElement2 = document.getElementById('submitenquiry');
        linkElement2.textContent = "Sending...";
        const payload = {
            password: 'foxyfoxy',
            toEmail: 'graphicalassets@gmail.com',
            subject: "Enquiry",
            name: firstName + ' ' + lastName,
            email: email,
            number: number,
            artistName: artistName,
            topics: topics,
            location: location,
            details: details
        };

        try {
            if (isValueEmpty(email) || isValueEmpty(payload.name)) {
                return toast.error('Error: Missing or Invalid required input (*)', {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            const response = await fetch('https://mystuber-vercel.vercel.app/email/emailEnquiry', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': true
                },
                body: JSON.stringify(payload),
            });
            const data = await response.status;
            if (data === 200 || data == 200) {
                setSentEmail(true);
                linkElement2.textContent = "Request Sent";
                toast.success('Request Sent Successfull! We will reach you out', {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            else {
                toast.error('Error: Server is busy. Please try again later', {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('Error: Server is busy. Please try again later', {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    return (
        <div className="App">
            <ToastContainer />
            <Header />
            <div>
                <div className="image-container">
                    <img src={banner} id="banner" style={{ objectFit: 'cover' }} alt="myatuberassets banners"></img>
                    <div className="overlay"></div>
                    <div className="image-txt-home">
                        <img src={dotsimg} className="dotsstyle" />
                        <p style={{ "fontWeight": "500", "fontSize": "24px" }}>For Art as unique as you</p>
                        <h3 style={{ "width": "100%" }}>Make Your Dream Come True</h3>
                        <br />
                        <p>Elevate your VTuing Journey with our Spectacular Design Artworks!</p>
                        <img src={MYA_Assets} />
                        <p style={{ "textAlign": "left", "padding": "0px 30%" }}>| You will be getting:</p>
                        <div className='packagesbtncontainer'>
                            <a href="#packages"><button className='ourpackagesheaderbtn'>Our Packages</button></a>
                            <a href="#CustomModel"><button className='ourpackagesheaderbtn'>Our Products</button></a>
                        </div>
                    </div>
                </div>
                <div className="image-container">
                    <img className='secondCover' src={topbg} height='auto'></img>
                </div>
            </div>
            <section id='categories'>
                <h2>Top Categories For <br /> Any <span style={{ color: 'red' }}>Streamer</span></h2>
                <p>Explore our Top Design Categories for an unforgettable Streaming Assets!</p>
                <div className="box-container">
                    <Link to='/Categories/Models' style={{ textDecoration: 'none' }}>
                        <div className="box zoom">
                            <img src={models} className="categoryimgshadow" />
                            <br />
                            Models
                        </div>
                    </Link>
                    <Link to='/Categories/Overlay' style={{ textDecoration: 'none' }}>
                        <div className="box zoom">
                            <img src={overrlay} className="categoryimgshadow" />
                            <br />
                            Overlay
                        </div>
                    </Link>
                    <Link to='/Categories/Animation' style={{ textDecoration: 'none' }}>
                        <div className="box zoom">
                            <img src={animation} className="categoryimgshadow" />
                            <br />
                            Animation
                        </div>
                    </Link>
                    <Link to='/Categories/Rigging' style={{ textDecoration: 'none' }}>
                        <div className="box zoom">
                            <img src={rigging} className="categoryimgshadow" />
                            <br />
                            Rigging
                        </div>
                    </Link>
                    <Link to='/Categories/Banner' style={{ textDecoration: 'none' }}>
                        <div className="box zoom">
                            <img src={banners} className="categoryimgshadow" />
                            <br />
                            Banner
                        </div>
                    </Link>
                    <Link to='/Categories/Artworks' style={{ textDecoration: 'none' }}>
                        <div className="box zoom">
                            <img src={artwork} className="categoryimgshadow" />
                            <br />
                            Artworks
                        </div>
                    </Link>
                </div>
            </section>
            <section id='aboutus'>
                <img src={halfbutterfly} className="halfbutterfly" />
                <div style={{ "display": "flex", "alignItems": "center" }}>
                    <div style={{ "textAlign": "center", "padding": "0px 10px 0px", "paddingTop": "20px", "paddingBottom": "10px" }}>
                        <h1>About Us</h1>
                        <h2>Why you should <span>Trust Us</span></h2>
                        <p>Our team of talent designers specializes in creating custom graphics and animations for Vtubers to bring their virtual personas to life and help you to establish a unique brand identity and stand out<br /> We understand the importance of having a strong visual presence in the fast-growing world of Vtubing and build our clients'vision and brand values to reality</p>
                        <a >See More Information -</a>
                    </div>
                    <section className="image-container2 more-info">
                        <img src={aboutusimg} width={'80%'}></img>
                    </section>
                </div>
            </section>
            <section id='banner2' className="image-container">
                <img src={banner2} width='100%' ></img>
                <img src={overlayart} width='60%' className="overlay-banner2"></img>
                <div className="overlay" style={{ width: '80%', borderRadius: '20px', margin: '-3px 10%' }}></div>
                <div className="image-txt-home shadow-left-text">
                    <p>MAKE A LEAP CHOOSE A PACKAGE</p>
                    <h4>Get <span style={{ color: '#da5959', fontWeight: 'bold' }}>Everything</span></h4>
                    <h4>you need for your <span style={{ color: '#e7e735', fontWeight: 'bold' }}>Debut.</span></h4>
                </div>
            </section>
            <div className='pakagesbg'>
                <section id='packages'>
                    <h2>Top & Only <span style={{ color: 'red', fontWeight: 'bold' }}>Packages</span> you need <br /> For your <span style={{ color: 'purple', fontWeight: 'bold' }}>Debut</span></h2>
                    <p>Discover our captivating Debut Packages, Tailored to Make your Steamer Launch Truly Remarkable!</p>
                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                        <div className='packageContainer'>
                            <h3><span style={{ color: 'white' }}>Starter Package</span> <br /> $0/<span className='onetime'>1 time</span></h3>
                            <hr />
                            <ul>
                                <li>&#x2714; OC Concept Art</li>
                                <li>&#x274C; 2D Model</li>
                                <li>&#x274C; Rigging</li>
                                <li>&#x274C; Overlay Bundle</li>
                                <li>&#x274C; Overlay Animation Bundle</li>
                                <li>&#x274C; Emotes</li>
                                <li>&#x274C; Logo + Animation</li>
                                <li>&#x274C; Digital Art Banner</li>
                                <li>&#x274C; PFP</li>
                                <li>&#x274C; Digital Products</li>
                                <li>&#x274C; Artworks</li>
                                <li>&#x274C; Stream Room</li>
                                <li>&#x274C; Animates Emotes</li>
                                <li>&#x274C; Scheduals</li>
                                <li>&#x274C; YT Thumbnails</li>
                            </ul>
                            <button className='packagebtn btn-shadow' id='StarterPackage' onClick={() => handleButtonClick('Starter')}>Get Started</button>
                        </div>
                        <div className='packageContainer midpackage'>
                            <h3><span style={{ color: '#ffff8c' }}>Premium Debut</span> <br /> $1,500/<span className='onetime'>1 time</span></h3>
                            <hr />
                            <ul>
                                <li>&#x2714; OC Concept Art</li>
                                <li>&#x2714; 2D Model</li>
                                <li>&#x2714; Rigging</li>
                                <li>&#x2714; Overlay Bundle</li>
                                <li>&#x2714; Overlay Animation Bundle</li>
                                <li>&#x2714; Emotes</li>
                                <li>&#x2714; Logo + Animation</li>
                                <li>&#x2714; Digital Art Banner</li>
                                <li>&#x2714; PFP</li>
                                <li>&#x2714; Digital Products</li>
                                <li>&#x2714; Artworks</li>
                                <li>&#x274C; Stream Room</li>
                                <li>&#x274C; Animates Emotes</li>
                                <li>&#x274C; Scheduals</li>
                                <li>&#x274C; YT Thumbnails</li>
                            </ul>
                            <button className='packagebtn btn2 btn-shadow' id='Premium Package' onClick={() => handleButtonClick('Premium')}>Get Started</button>
                        </div>
                        <div className='packageContainer'>
                            <h3><span style={{ color: 'blue' }}>Diamond Debut</span> <br /> $2,000/<span className='onetime'>1 time</span></h3>
                            <hr />
                            <ul>
                                <li>&#x2714; OC Concept Art</li>
                                <li>&#x2714; 2D Model</li>
                                <li>&#x2714; Rigging</li>
                                <li>&#x2714; Overlay Bundle</li>
                                <li>&#x2714; Overlay Animation Bundle</li>
                                <li>&#x2714; Emotes</li>
                                <li>&#x2714; Logo + Animation</li>
                                <li>&#x2714; Digital Art Banner</li>
                                <li>&#x2714; PFP</li>
                                <li>&#x2714; Digital Products</li>
                                <li>&#x2714; Artworks</li>
                                <li>&#x2714; Stream Room</li>
                                <li>&#x2714; Animates Emotes</li>
                                <li>&#x2714; Scheduals</li>
                                <li>&#x2714; YT Thumbnails</li>
                            </ul>
                            <button className='packagebtn btn-shadow' id='Diamond Package' onClick={() => handleButtonClick('Diamond')}>Get Started</button>
                        </div>
                    </div>
                </section>
            </div>
            <section id='CustomModel'>
                <h2>Get Yourself a <span style={{ color: 'red' }}>Custom</span> <br /><span style={{ color: 'purple' }}>Model</span></h2>
                <p>Forge Your Unique Virtual Identity with our Exquisite Custom Models</p>
                <button className='btn' onClick={() => handleButtonClick('Model')}>Our Packages</button>
                <div>
                    <img src={custommodel} usemap="#image-map" width={"100%"}></img>
                    <map name="image-map">
                        <Link to='/Categories/Overlay' style={{ textDecoration: 'none' }}>
                            <area target="_self" alt="Custom overlay" title="Custom overlay" href="" coords="610,164,41" shape="circle" />
                        </Link>
                        <Link to='/Categories/Rigging' style={{ textDecoration: 'none' }}>
                            <area target="_self" alt="Rigging" title="Rigging" href="" coords="610,271,41" shape="circle" />
                        </Link>
                        <Link to='/Categories/Artworks' style={{ textDecoration: 'none' }}>
                            <area target="_self" alt="Artworks" title="Artworks" href="" coords="611,374,40" shape="circle" />
                        </Link>
                        <Link to='/Categories/Animation' style={{ textDecoration: 'none' }}>
                            <area target="_self" alt="Animation" title="Animation" href="" coords="610,481,40" shape="circle" />
                        </Link>
                        <Link to='/Categories/Banner' style={{ textDecoration: 'none' }}>
                            <area target="_self" alt="Banner" title="Banner" href="" coords="610,608,41" shape="circle" />
                        </Link>
                        <Link to='/Categories/Animation' style={{ textDecoration: 'none' }}>
                            <area target="_self" alt="Stream Rome" title="Stream Rome" href="" coords="608,715,38" shape="circle" />
                        </Link>
                        <Link to='/Categories/Model' style={{ textDecoration: 'none' }}>
                            <area target="_self" alt="Model" title="Model" href="" coords="1544,339,1174,171" shape="rect" />
                        </Link>
                        <Link to='/Categories/Artworks' style={{ textDecoration: 'none' }}>
                            <area target="_self" alt="Art Style" title="Art Style" href="" coords="1179,365,1546,533" shape="rect" />
                        </Link>
                        <area target="_self" alt="Custom Outfit" title="Custom Outfit" href="" coords="1176,554,1544,720" shape="rect" />
                        <Link to='/Categories/Model' style={{ textDecoration: 'none' }}>
                            <area target="_self" alt="Model" title="Model" href="" coords="801,126,1110,760" shape="rect" />
                        </Link>
                    </map>
                </div>


            </section>
            <div className='productsbg'>
                <section id='exploreProducts'>
                    <div>
                        <div>
                            <h2>Explore our <span style={{ color: 'red' }}>Products</span></h2>
                            <div className='flexbox'>
                                <button className='tab-active'>Hoddie</button>
                                <button className='tab'>Hand</button>
                                <button className='tab'>Cups</button>
                            </div>
                        </div>
                        <button className='btn btn2' onClick={goToProducts}>Our Products</button>
                    </div>
                    <div className='productsContainer' style={{ "alignItems": "stretch" }} id='productsHome' onClick={goToProducts}>
                        <div className='productCard'>
                            <div className="image-container indexminus">
                                <img src={redHoodie} width={'100%'} alt="Red Hoodie"></img>
                                {/* <div className="image-text">
      <p className='nomargin'><b>$5</b></p>
    </div> */}
                            </div>
                            <div className='dark-bg'>
                                <p>Cozy Hoodie<br />Red Black</p>
                                <div>
                                    <button className='add-btn'>+</button>
                                </div>
                            </div>
                        </div>
                        <div className='productCard'>
                            <div className="image-container indexminus">
                                <img src={Hoodie2} width={'100%'} alt="Blue Hoodie"></img>
                                {/* <div className="image-text">
      <p className='nomargin'><b>$5</b></p>
    </div> */}
                            </div>
                            <div className='dark-bg'>
                                <p>Cozy Hoodie<br />Blue Black</p>
                                <div>
                                    <button className='add-btn'>+</button>
                                </div>
                            </div>
                        </div>
                        <div className='productCard'>
                            <div className="image-container indexminus">
                                <img src={Hoodie4} width={'100%'} alt="Dark Hoodie"></img>
                                {/* <div className="image-text">
      <p className='nomargin'><b>$5</b></p>
    </div> */}
                            </div>
                            <div className='dark-bg'>
                                <p>Cozy Hoodie<br />Dark Black</p>
                                <div>
                                    <button className='add-btn'>+</button>
                                </div>
                            </div>
                        </div>
                        <div className='productCard'>
                            <div className="image-container indexminus">
                                <img src={Hoodie5} width={'100%'} alt="Soft Hoodie"></img>
                                {/* <div className="image-text">
      <p className='nomargin'><b>$5</b></p>
    </div> */}
                            </div>
                            <div className='dark-bg'>
                                <p>Cozy Hoodie<br />Soft Black</p>
                                <div>
                                    <button className='add-btn'>+</button>
                                </div>
                            </div>
                        </div>
                        <div className='productCard'>
                            <div className="image-container indexminus">
                                <img src={Hoodie3} width={'100%'} alt="Yellow Hoodie"></img>
                                {/* <div className="image-text">
      <p className='nomargin'><b>$5</b></p>
    </div> */}
                            </div>
                            <div className='dark-bg'>
                                <p>Cozy Hoodie<br />Yellow Black</p>
                                <div>
                                    <button className='add-btn'>+</button>
                                </div>
                            </div>
                        </div>
                        <div className='productCard'>
                            <div className="image-container indexminus">
                                <img src={Hoodie6} width={'100%'} alt="Green Hoodie"></img>
                                {/* <div className="image-text">
      <p className='nomargin'><b>$5</b></p>
    </div> */}
                            </div>
                            <div className='dark-bg'>
                                <p>Cozy Hoodie<br />Green Black</p>
                                <div>
                                    <button className='add-btn'>+</button>
                                </div>
                            </div>
                        </div>
                        <div className='productCard'>
                            <div className="image-container indexminus handcard">
                                <img src={Handholding} width={'100%'} alt="Hand Holding"></img>
                                {/* <div className="image-text">
      <p className='nomargin'><b>$5</b></p>
    </div> */}
                            </div>
                            <div className='dark-bg'>
                                <p>Hands Hold<br />Gesture</p>
                                <div>
                                    <button className='add-btn'>+</button>
                                </div>
                            </div>
                        </div>
                        <div className='productCard'>
                            <div className="image-container indexminus handcard">
                                <img src={Holdingmug} width={'100%'} alt="Hand Holding Mug"></img>
                                {/* <div className="image-text">
      <p className='nomargin'><b>$5</b></p>
    </div> */}
                            </div>
                            <div className='dark-bg'>
                                <p>Hands Hold<br />Gesture</p>
                                <div>
                                    <button className='add-btn'>+</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <section id='gamessection' >
                <a href="https://www.behance.net/myaassets1" target="_blank" rel="noopener noreferrer">
                    <img src={Portfolio} />
                </a>
            </section>
            <div className="enquiry-section">
                <div className="enquiry-form">
                    <h2>Need to Make an <span className="red-text">Enquiry?</span></h2>
                    <div>
                        <div className="form-group">
                            <input id='lastname' type="text" placeholder="Your Name*" />
                            <input id='firstname' type="text" placeholder="Sir Name*" />
                        </div>
                        <div className="form-group">
                            <input id='emailenquiry' type="text" placeholder="Your Email*" />
                            <input id='numberenquiry' type="text" placeholder="Number" />
                        </div>
                        <div className="form-group">
                            <input id="locationenquiry" type="text" placeholder="Location" />
                            <input id='artistenquiry' type="text" placeholder="Artist name or Department" />
                        </div>
                        <div className="form-group">
                            <input id='topicsenquiry' type="email" placeholder="Topics" />
                        </div>
                        <div className="form-group ">
                            <textarea id='detailsenquiry' placeholder="Details you have in your mind" ></textarea>
                        </div>
                        <button onClick={sendEnquiryEmail} id="submitenquiry">Submit Message</button>
                    </div>
                </div>
                <div className="enquiry-info">
                    <h2>Get In Touch With Us</h2>
                    <h1>We Care <span> About You</span></h1>
                    <p>Our team of talent designers specializes in creating custom graphics and animations for Vtubers to bring their virtual personas to life and help you to establish a unique brand identity and stand out<br /> We understand the importance of having a strong visual presence in the fast-growing world of Vtubing and build our clients'vision and brand values to reality</p>
                    <h3>More Info</h3>
                    <div className="enquiry-icons">
                        <a href="mailto:graphicalassets@gmail.com">
                            <div className="enquiry-icon purple-circle">
                                <i className="fa fa-envelope"></i>
                            </div>
                        </a>
                        <div className="enquiry-icon">
                            <a href='https://twitter.com/The_MYA_Assets' target="_blank" rel="noopener noreferrer">
                                <i className="fa fa-twitter"></i>
                            </a>
                        </div>
                        <div className="enquiry-icon">
                            <a href="https://www.behance.net/myaassets1" target="_blank" rel="noopener noreferrer">
                                <i className="fa fa-behance"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <section id="testimony">
                <div className='flex-div'>
                    <img src={mobilephone} width={"30%"} />
                    <div className='jumpinmobile'>
                        <h3>Testimonial</h3>
                        <h2>What Our <span style={{ color: 'red' }}>Clients Say About Us</span></h2>
                        <div className="testimonial-section">
                            <div className="testimonial">
                                <div className="testimonial-content">
                                    <p>"Awesome work from <span style={{ color: "#54cbf2" }}>@The_MYA_Assets</span> If you peeps need any emotes/overlay/art/2dmodels/rigging. They have everything. Do give them the support they deserve!"</p>
                                </div>
                                <div className="testimonial-info">
                                    <img src={female} alt="Testimonial 2" className="testimonial-picture" />
                                    <div className="testimonial-author-info">
                                        <p>@Rikari_Ikari</p>
                                    </div>
                                </div>
                            </div>

                            <div className="testimonial">
                                <div className="testimonial-content">
                                    <p>"My new overlays and intro/transition videos by <span style={{ color: "#54cbf2" }}>@The_MYA_Assets!</span> Thanks so much again!"</p>
                                </div>
                                <div className="testimonial-info">
                                    <img src={male} alt="Testimonial 2" className="testimonial-picture" />
                                    <div className="testimonial-author-info">
                                        <p>@lifewulfOG.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="testimonial">
                                <div className="testimonial-content">
                                    <p>"I have to thank you for the awesome work on my Twitter banner and new profile picture! Thank you for your amazing work!"</p>
                                </div>
                                <div className="testimonial-info">
                                    <img src={male} alt="Testimonial 2" className="testimonial-picture" />
                                    <div className="testimonial-author-info">
                                        <p>@KainDraconius</p>
                                    </div>
                                </div>
                            </div>

                            <div className="testimonial">
                                <div className="testimonial-content">
                                    <p>"Hey guys, you might want to check out commissioners <span style={{ color: "#54cbf2" }}>@The_MYA_Assets</span> She does really good jobs with commissions, overlays, banners, etc etc. If you mention me to her in a dm, I will be able to get discounts from her! So please show her some love!"</p>
                                </div>
                                <div className="testimonial-info">
                                    <img src={female} alt="Testimonial 1" className="testimonial-picture" />
                                    <div className="testimonial-author-info">
                                        <p>@Neo9_0</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            <section id='affiliate' className="image-container" onClick={handleButtonClick2}>
                <img src={affiliateimg} width={'80%'} height={'100%'} ></img>
                <div className="overlay" style={{ width: '80%', height: '100%' }} ></div>
                <div className="image-txt-home">
                    <p className='affHead'><b>SHARE OUR LINK</b></p>
                    <h4 className='affHead'>Share us to get <span style={{ color: 'red', fontWeight: 'bold' }}>Free</span></h4>
                    <h4 className='affHead'>Monthly Assets <span style={{ color: 'yellow', fontWeight: 'bold' }}>Bundles.</span></h4>
                </div>
            </section>
            <section id='aboutus-contact' className='nowrap'>
                <div>
                    <img src={Logo} alt="Logo" className="logo-png" />
                    <p>We understand the importance of having a strong visual presence in the fast-growing world of Vtubing and build our clients'vision and brand values to reality</p>

                    <div className="enquiry-icons">
                        <a href="mailto:graphicalassets@gmail.com">
                            <div className="enquiry-icon purple-circle">
                                <i className="fa fa-envelope"></i>
                            </div>
                        </a>
                        <div className="enquiry-icon purple-circle">
                            <a href='https://twitter.com/The_MYA_Assets' target="_blank" rel="noopener noreferrer">
                                <i className="fa fa-twitter"></i>
                            </a>
                        </div>
                        <div className="enquiry-icon purple-circle">
                            <a href="https://www.behance.net/myaassets1" target="_blank" rel="noopener noreferrer">
                                <i className="fa fa-behance"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div className='links'>
                    <h2>Useful Links</h2>
                    <ul>
                        <li>Home</li>
                        <li>About Us</li>
                        <li>Service</li>
                        <li>Packages</li>
                        <li>Digital Products</li>
                        <li>Contact Us</li>
                    </ul>
                </div>
                <div className='contactlinks'>
                    <h2>Contact Us</h2>
                    <ul>
                        <li><i className="fa fa-clock-o" aria-hidden="true"></i> Mon - Fri: 09:00 - 18:00</li>
                        <li><i className="fa fa-envelope"></i> graphicalassets@gmail.com</li>
                        <li><i className="fa fa-map-marker" aria-hidden="true"></i> Canada Toronto</li>
                    </ul>
                </div>
                <Link to="/portfolio">
                    <div className='contactlinks'>
                        <h2>Our Portfolio</h2>
                        <img src={Portfolio} className="protfolio"></img>
                    </div>
                </Link>
            </section>
            <footer>Copyrigths 2023 MyAtuberAssets</footer>

            {/* POPUP FORM */}
            <div>
                {showPopup && (
                    <>
                        <div className="backdrop"></div>
                        <div className="popup-form">
                            <button className="close-button" onClick={handleCloseClick}>
                                <FaTimes />
                            </button>
                            <h2>Part:1 Your Basic <span style={{ color: 'red', fontWeight: 'bolder' }}>Information</span></h2>
                            <div className="input-group">
                                <label htmlFor="name">Name*</label>
                                <input type="text" id="name" name="name" required />
                            </div>

                            <div className="input-group">
                                <label htmlFor="email">Email*</label>
                                <input type="email" id="email" name="email" required />
                            </div>
                            <div className="input-group">
                                <label htmlFor="name">Twitter Account</label>
                                <input type="text" id="Twitter" name="name" required />
                            </div>
                            <div className="input-group">
                                <label htmlFor="location">Location</label>
                                <input type="text" id="Location" name="location" required />
                                <label htmlFor="name">debut date</label>
                                <input type="date" id="debutdate" name="name" />
                            </div>
                            <button className='btn' onClick={goToPackages}>Next</button>
                        </div>
                    </>
                )}
                {showPopup2 && (
                    <>
                        <div className="backdrop"></div>
                        <div className="popup-form">
                            <button className="close-button" onClick={handleCloseClick2}>
                                <FaTimes />
                            </button>
                            <h2>Your <span style={{ color: 'red', fontWeight: 'bolder' }}>Information</span></h2>
                            <div className="input-group">
                                <label htmlFor="name">Name*</label>
                                <input type="text" id="name2" name="name" required />
                            </div>
                            <div className="input-group">
                                <label htmlFor="email">Email*</label>
                                <input type="email" id="email2" name="email" required />
                            </div>
                            <div className="input-group">
                                <label htmlFor="name">Twitter Account</label>
                                <input type="text" id="Twitter2" name="name" required />
                            </div>
                            <div className="input-group">
                                <label htmlFor="location">Location</label>
                                <input type="text" id="Location2" name="location" required />
                            </div>
                            <button className='btn' id='generateAffiliateLinkbtn' onClick={generateAffiliateLink}>Get Link</button>
                            <p id='generateAffiliateLink'></p>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default Home;