import { React, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../Common/Header'
import '../App.css'
import MYA_Assets from '../assets/myaAssets.webp';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function Packages() {
  useEffect(() => {
    const windowHeight = window.innerHeight;
    const offset = windowHeight * 0.09;
    window.scrollTo(0, offset);
  }, []);
  const [sentEmail, setSentEmail] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const packagename = queryParams.getAll('packagename').toString();
  const name = queryParams.getAll('name').toString();
  const email = queryParams.getAll('email').toString();
  const twitterInput = queryParams.getAll('twitterInput').toString();
  const locationInput = queryParams.getAll('locationInput').toString();
  const debutDateInput = queryParams.getAll('debutDateInput').toString();
  async function sendEmail() {
    if (sentEmail) {
      toast.success('Yes Yes, We Received your Email! We will reach you out', {
        position: toast.POSITION.TOP_RIGHT
      });
      return
    }
    var colorThemeInput = document.getElementById('input-color-theme');
    var colorTheme = colorThemeInput.value;
    var conceptStyleInput = document.getElementById('input-concept');
    var conceptStyle = conceptStyleInput.value;
    var preferredDesignerInput = document.getElementById('prefered-designer') || "";
    var preferredDesigner = preferredDesignerInput.value;
    if (colorTheme.length < 2 || conceptStyle.length < 2 || email.length < 2)
      return toast.error('Fill the details first please', {
        position: toast.POSITION.TOP_RIGHT
      });
    try {
      const submitElement = document.getElementById('submitrequest');
      submitElement.textContent = "Sending...";
      const response = await fetch('https://mystuber-vercel.vercel.app/email/emailPackage', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ password: 'foxyfoxy', toEmail: 'graphicalassets@gmail.com', packagename: packagename, subject: "Package", colorTheme: colorTheme, conceptStyle: conceptStyle, preferredDesigner: preferredDesigner, name: name, email: email, twitterInput: twitterInput, locationInput: locationInput, debutDateInput: debutDateInput }),
      });
      const data = await response.status;
      if (data === 200 || data == 200) {
        setSentEmail(true);
        submitElement.textContent = "Sent";
        toast.success('Request Sent Successfull! We will reach you out', {
          position: toast.POSITION.TOP_RIGHT
        });
      }
      else {
        toast.error('Error: Server is busy. Please try again later', {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Error: Server is busy. Please try again later', {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  }
  return (
    <>
      <Header />
      <ToastContainer />
      <div className="containerCat">
        <div className="top-section">
        </div>
        <div className="content">
          <img src={MYA_Assets} />
        </div>
      </div>
      <section className="bg-section">
        <div className="container" style={{ "background": "rgb(221,191,114)", "background": "linear-gradient(43deg, rgba(221,191,114,1) 0%, rgba(255,249,202,1) 29%, rgba(255,150,231,1) 100%)" }}>
          <h2>How this Process <span style={{ color: 'red', fontWeight: 'bold' }}>Works?</span></h2>
          <p>
            All of your given details would be sent to the Official Team and it would be checked by them
          </p>
          <p>
            when they Approved all of your details you will recived a mail on your given email that your Order Details are all good to go
          </p>
          <p>
            if the given detalls are not clear you will recieve a different email asking simple Question in order to understand your Design Art Taste After you recieved the email stating that your Details are Good to go we will then proceed futher for the Upfront.
          </p>
          <h2>What will be the <span style={{ color: 'red', fontWeight: 'bold' }}>Payment Process?</span></h2>
          <p>
            In order to make our Clients feel more comfortable we do not demands the whole Payment right from the start but just an Upfront fee to book your Project Slot in our list So that we can start your Work and the rest of the payment you can send easily when you Approved your Desired Design! This is the Most Safest Process we Designed for Our Clients So that they can get their Dream Assets Without Being worry of getting Scammed!
          </p>
          <p>
            "Let's work together to bring your <span style={{ color: 'red', fontWeight: 'bold' }}> Vtuber vision to life.</span> "
          </p>
        </div>
      </section>
      <section className="bg-section2">
        <div className="container" style={{ "backgroundColor": "rgb(202 202 202)" }}>
          <h2>The Theme you want to go with <span style={{ color: 'red', fontWeight: 'bold' }}>Your Channel</span></h2>
          <input id="input-color-theme" type="text" placeholder='Color theme which suits you the most?' />
          <br />
          <textarea id='input-concept' type="text" placeholder='Concept Style, details you have in your mind (Reference Image would work aswell)' />
          <br />
          <input id='prefered-designer' type="text" placeholder='Do you have a Prefered Designer You specifically want to work on your project? (optional)' />
          <br />
          <button id="submitrequest" onClick={sendEmail} style={{ "background": "rgb(252,93,93)", "background": "radial-gradient(circle, rgba(252,93,93,1) 0%, rgba(255,44,44,1) 100%)" }}><b>Submit Request</b></button>
        </div>
      </section>
      <section className='thirdsection'>
        <div className="enquiry-icons">
          <a href="mailto:graphicalassets@gmail.com">
            <div className="enquiry-icon purple-circle">
              <i className="fa fa-envelope"></i>
            </div>
          </a>
          graphicalassets@gmail.com
        </div>
      </section>
      <section className='thirdsection'>
        <div className="enquiry-icon purple-circle" style={{ "background": "white" }}>
          <a href='https://twitter.com/The_MYA_Assets' target="_blank" rel="noopener noreferrer">
            <i style={{ "color": "black" }} className="fa fa-twitter"></i>
          </a>
        </div>
        <div className="enquiry-icon purple-circle" style={{ "background": "white" }}>
          <a href="https://www.behance.net/myaassets1" target="_blank" rel="noopener noreferrer">
            <i style={{ color: 'black' }} className="fa fa-behance"></i>
          </a>
        </div>
      </section>
      <footer>Copyrigths 2023 MyAtuberAssets</footer>
    </>
  )
}

export default Packages;