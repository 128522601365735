import '../products.css';
import '../products.css';
import React, { useState, useEffect } from 'react';
import Header from '../Common/Header';
import redHoodie from '../assets/products/Cozyhoddie1.webp';
import Hoodie2 from '../assets/products/Cozyhoddie2.webp';
import Hoodie3 from '../assets/products/Cozyhoddie3.webp';
import Hoodie4 from '../assets/products/Cozyhoddie4.webp';
import Hoodie5 from '../assets/products/Cozyhoddie5.webp';
import affiliateimg from '../assets/Backgrounds/affiliate.jpg';
import Hoodie6 from '../assets/products/Cozyhoddie6.webp';
import Handholding from '../assets/products/handholding.webp';
import Holdingmug from '../assets/products/holdingmung1.webp';
import { FaTimes } from "react-icons/fa";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Products() {
    useEffect(() => {
        const windowHeight = window.innerHeight;
        const offset = windowHeight * 0.06;
        window.scrollTo(0, offset);
    }, []);
    const [sentEmail, setSentEmail] = useState(false);
    const [showPopup2, setShowPopup2] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState("");
    const handleButtonClick = (product) => {
        setSelectedProduct(product)
        setShowPopup(true);
    };
    const handleCloseClick = () => {
        setShowPopup(false);
    };
    const isValueEmpty = (value) => {
        return value.trim() === "";
    };
    const handleButtonClick2 = (buttonId) => {
        setShowPopup2(true);
    };
    const handleCloseClick2 = () => {
        setShowPopup2(false);
    };
    async function sendEmail() {
        const nameInput = document.getElementById("name").value;
        const twitterInput = document.getElementById("Twitter").value || "";
        const emailInput = document.getElementById("email").value;
        if (sentEmail) {
            toast.success('Yes Yes, We Received your Email! We will reach you out', {
                position: toast.POSITION.TOP_RIGHT
            });
            return
        }        
        if (isValueEmpty(nameInput) || isValueEmpty(emailInput)) {
            return toast.error('Fill the required (*) details', {
                position: toast.POSITION.TOP_RIGHT
              });
        }
        if (nameInput.length < 2 || !emailInput.value.includes('@'))
        return toast.error('Invaild Information in required (*) details', {
                position: toast.POSITION.TOP_RIGHT
            });        
        try {
            const linkElement2 = document.getElementById('submitproduct');
            linkElement2.textContent = "Sending...";
            const response = await fetch('https://mystuber-vercel.vercel.app/email/emailProduct', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ password: 'foxyfoxy', toEmail: 'graphicalassets@gmail.com', name: nameInput, email: emailInput, twitterInput: twitterInput, product: selectedProduct }),
            });
            const data = await response.status;
            if (data === 200 || data == 200) {
                setSentEmail(true);
                linkElement2.textContent = "Request Sent";
                toast.success('Request Sent Successfull! We will reach you out', {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            else {
                toast.error('Error: Server is busy. Please try again later', {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('Error: Server is busy. Please try again later', {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }
    return (
        <>
            <div className='productPage'>
                <Header />
                <ToastContainer />
                <h2 className='heading'>Our <span style={{ color: 'red' }}>Products </span></h2>
                <div className='glassEffect'>
                    <div className='productsbg'>
                        <section id='exploreProducts'>
                            <h3 className='containerHeading'>Styled <span style={{ color: 'red' }}>Hoddies </span></h3>
                            <div className='productsContainer'>
                                <div className='productCard'>
                                    <div className="image-container indexminus">
                                        <img src={redHoodie} width={'100%'} alt="Myattubersasset Cozy Hoddie Red Black"></img>
                                        <div className="image-text">
                                            <p className='nomargin'><b>$5</b></p>
                                        </div>
                                    </div>
                                    <div className='dark-bg'>
                                        <p>Cozy Hoddie<br />Red Black</p>
                                        <button onClick={() => handleButtonClick('Cozy Hoddie Red Black')} className='add-btn'>+</button>
                                    </div>
                                </div>
                                <div className='productCard'>
                                    <div className="image-container indexminus">
                                        <img src={Hoodie2} width={'100%'} alt="Myattubersasset Cozy Hoddie Blue Black"></img>
                                        <div className="image-text">
                                            <p className='nomargin'><b>$5</b></p>
                                        </div>
                                    </div>
                                    <div className='dark-bg'>
                                        <p>Cozy Hoddie<br />Blue Black</p>
                                        <button onClick={() => handleButtonClick('Cozy Hoddie Blue Black')} className='add-btn'>+</button>
                                    </div>
                                </div>
                                <div className='productCard'>
                                    <div className="image-container indexminus">
                                        <img src={Hoodie4} width={'100%'} alt="Myattubersasset Cozy Hoddie Dark Black"></img>
                                        <div className="image-text">
                                            <p className='nomargin'><b>$5</b></p>
                                        </div>
                                    </div>
                                    <div className='dark-bg'>
                                        <p>Cozy Hoddie<br />Dark Black</p>
                                        <button onClick={() => handleButtonClick('Cozy Hoddie Dark Black')} className='add-btn'>+</button>
                                    </div>
                                </div>
                            </div>

                            {/* <h3 className='containerHeading'>Second <span style={{ color: 'red' }}>Section </span></h3> */}
                            <div className='productsContainer'>
                                <div className='productCard'>
                                    <div className="image-container indexminus">
                                        <img src={Hoodie6} width={'100%'} alt="Myattubersasset Cozy Hoddie Green Black"></img>
                                        <div className="image-text">
                                            <p className='nomargin'><b>$5</b></p>
                                        </div>
                                    </div>
                                    <div className='dark-bg'>
                                        <p>Cozy Hoddie<br />Green Black</p>
                                        <button onClick={() => handleButtonClick('Cozy Hoddie Green Black')} className='add-btn'>+</button>
                                    </div>
                                </div>
                                <div className='productCard'>
                                    <div className="image-container indexminus">
                                        <img src={Hoodie5} width={'100%'} alt="Cozy Hoddie Soft Black"></img>
                                        <div className="image-text">
                                            <p className='nomargin'><b>$5</b></p>
                                        </div>
                                    </div>
                                    <div className='dark-bg'>
                                        <p>Cozy Hoddie<br />Soft Black</p>
                                        <button onClick={() => handleButtonClick('Cozy Hoddie Soft Black')} className='add-btn'>+</button>
                                    </div>
                                </div>
                                <div className='productCard'>
                                    <div className="image-container indexminus">
                                        <img src={Hoodie3} width={'100%'} alt="Cozy Hoddie Yellow Black"></img>
                                        <div className="image-text">
                                            <p className='nomargin'><b>$5</b></p>
                                        </div>
                                    </div>
                                    <div className='dark-bg'>
                                        <p>Cozy Hoddie<br />Yellow Black</p>
                                        <button onClick={() => handleButtonClick('Cozy Hoddie Yellow Black')} className='add-btn'>+</button>
                                    </div>
                                </div>
                            </div>

                            <h3 className='containerHeading'>Hand <span style={{ color: 'red' }}>Gestures </span></h3>
                            <div className='productsContainer'>
                                <div className='productCard'>
                                    <div className="image-container indexminus">
                                        <img src={Hoodie6} width={'100%'} alt="Cozy Hoddie Green Black"></img>
                                        <div className="image-text">
                                            <p className='nomargin'><b>$5</b></p>
                                        </div>
                                    </div>
                                    <div className='dark-bg'>
                                        <p>Cozy Hoddie<br />Green Black</p>
                                        <button onClick={() => handleButtonClick('Cozy Hoddie Green Black')} className='add-btn'>+</button>
                                    </div>
                                </div>
                                <div className='productCard'>
                                    <div className="image-container indexminus">
                                        <img src={Handholding} width={'100%'} alt="Hands Holding Gesture"></img>
                                        <div className="image-text">
                                            <p className='nomargin'><b>$5</b></p>
                                        </div>
                                    </div>
                                    <div className='dark-bg'>
                                        <p>Hands Holding<br />Gesture</p>
                                        <button onClick={() => handleButtonClick('Hands Holding Gesture')} className='add-btn'>+</button>
                                    </div>
                                </div>
                                <div className='productCard'>
                                    <div className="image-container indexminus">
                                        <img src={Holdingmug} width={'100%'} alt="Hand Holding Cup Gesture"></img>
                                        <div className="image-text">
                                            <p className='nomargin'><b>$5</b></p>
                                        </div>
                                    </div>
                                    <div className='dark-bg'>
                                        <p>Hand Holding Cup<br />Gesture</p>
                                        <button onClick={() => handleButtonClick('Hand Holding Cup Gesture')} className='add-btn'>+</button>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                <section id='affiliate' className="image-container" onClick={handleButtonClick2}>
                <img src={affiliateimg} width={'80%'} height={'100%'} ></img>
                <div className="overlay" style={{ width: '80%', height: '100%' }} ></div>
                <div className="image-txt">
                    <p className='affHead'><b>SHARE OUR LINK</b></p>
                    <h4 className='affHead'>Share us to get <span style={{ color: 'red', fontWeight: 'bold' }}>Free</span></h4>
                    <h4 className='affHead'>Monthly Assets <span style={{ color: 'yellow', fontWeight: 'bold' }}>Bundles.</span></h4>
                </div>
            </section>
            <div class="text-div">
                <p>In order to enter in your Monthly Free Assets Giveaways you would have to insert your basic information</p>
                <p>and then generate your link from the button below</p>
                <p>and after that you have to share our Website link on your Twitter Handles for more then 29 days</p>
                <p>in which you would be observe by the team</p>
                <p>after doing that you would recieve email from our team with the Free Assets Bundle on the current Month</p>
                
            </div>

            <div class="email-input">
                <h5>Get Started</h5>
                <h2>Get Our Official <span style={{ color: 'red' }}>Website Like</span></h2>
                <div class="input-group">
                    <input type="email" placeholder="Enter your email" />
                    <button className='submit-button' type="submit">Submit</button>
                </div>
            </div>

                <div className='butterfly'>
                    <h4 className='bottomHeading'>Uploading More <span style={{ color: 'red' }}>Soon </span>!</h4>
                </div>
                <footer>Copyrigths 2023 MyAtuberAssets</footer>
            </div>
            {showPopup && (
                <>
                    <div className="backdrop"></div>
                    <div className="popup-form">
                        <button className="close-button" onClick={handleCloseClick}>
                            <FaTimes />
                        </button>
                        <h2 style={{ "textAlign": "center" }}>Your Contact <span style={{ color: 'red', fontWeight: 'bolder' }}>Information</span></h2>
                        <div className="input-group">
                            <label htmlFor="name">Name*</label>
                            <input type="text" id="name" name="name" required />
                            <label htmlFor="name">Twitter Account</label>
                            <input type="text" id="Twitter" name="name" required />
                        </div>
                        <div className="input-group">
                            <label htmlFor="email">Email*</label>
                            <input type="email" id="email" name="email" required />
                        </div>
                        <p style={{ "color": "gray", "fontSize": "10px", "float": "right" }}>*we will deliver you by email</p>
                        <br />
                        <br />
                        <button className='btn' style={{ "float": "right" }} onClick={sendEmail} id="submitproduct">Submit</button>
                    </div>
                </>
            )}
        </>
    )
}

export default Products;

