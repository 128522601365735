import { useState } from 'react';
import '../Portfolio.css';
import react, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import Header from '../Common/Header';
import portfolio1 from '../assets/overlay/1.webp';
import portfolio2 from '../assets/overlay/2.webp';
import portfolio3 from '../assets/overlay/3.webp';
import portfolio4 from '../assets/overlay/4.webp';
import portfolio5 from '../assets/overlay/5.webp';
import portfolio6 from '../assets/overlay/6.webp';
import portfolio7 from '../assets/overlay/7.webp';
import portfolio8 from '../assets/overlay/8.webp';
import portfolio9 from '../assets/overlay/9.webp';
import portfolio10 from '../assets/overlay/10.webp';
import portfolio11 from '../assets/overlay/11.webp';
import portfolio12 from '../assets/overlay/12.webp';

function Portfolio() {    
    useEffect(() => {
        const windowHeight = window.innerHeight;
        const offset = windowHeight * 0.06;
        window.scrollTo(0, offset);
      }, []);
    return (
        <>
            <div className='PortfolioPage'>
                <Header />
                <h2 className='heading'>Our <span style={{ color: 'red' }}>Portfolio </span></h2>
                <div className='glassEffect'>
                    <div className='productsbg'>
                        <div className="image-gallery">
                            <div className='column'>
                                <img src={portfolio1} alt="Myattuberassets-online-game-design" />
                            </div>
                            <div className='column'>
                                <img src={portfolio2} alt="Myattuberassets-apex-legneds-design"/>
                            </div>
                            <div className='column'>
                                <img src={portfolio3} alt="Myattuberassets-penguin-game-design"/>
                            </div>
                            <div className='column'>
                                <img src={portfolio4} alt="Myattuberassets-penguin-game2-design"/>
                            </div>
                            <div className='column'>
                                <img src={portfolio5} alt="Myattuberassets-online-game-screen"/>
                            </div>
                            <div className='column'>
                                <img src={portfolio6} alt="Myattuberassets-online-game-screen2"/>
                            </div>
                            <div className='column'>
                                <img src={portfolio7} alt="Myattuberassets-online-game-screen3" />
                            </div>
                            <div className='column'>
                                <img src={portfolio8} alt="Myattuberassets-online-game-screen4" />
                            </div>
                            <div className='column'>
                                <img src={portfolio9} alt="Myattuberassets-online-game-screen5"/>
                            </div>
                            <div className='column'>
                                <img src={portfolio10} alt="Myattuberassets-online-game-screen6"/>
                            </div>
                        </div>
                        <div className="image-gallery">
                            <div className='column'>
                                <img src={portfolio11} alt="Myattuberassets-game-screen7"/>
                            </div>
                            <div className='column'>
                                <img src={portfolio12} alt="Myattuberassets-game-screen8"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='butterfly'>
                    <h4 className='bottomHeading'>Uploading More <span style={{ color: 'red' }}>Soon </span>!</h4>
                </div>
                <footer>Copyrigths 2023 MyAtuberAssets</footer>
            </div>
        </>
    )
}

export default Portfolio;

