import React, { useState } from 'react'
import icon from '../assets/logo.webp';
import { FaTimes } from "react-icons/fa";
import { Link } from 'react-router-dom';


function Header() {
    const [showPopup, setShowPopup] = useState(false);

    const handleButtonClick = () => {
        setShowPopup(true);
    };

    const handleCloseClick = () => {
        setShowPopup(false);
    };
    return (
        <>
            <header>
                <nav>
                    <img src={icon} width={'60px'} alt="Logo" />
                    <input type="checkbox" id="menu-toggle" />
                    <label htmlFor="menu-toggle" className="menu-icon">&#9776;</label>
                    <div className="menu-items">
                        <Link to="/" style={{ textDecoration: 'none' }}>Home</Link>                        
                        <a href="#aboutus" style={{ textDecoration: 'none' }}>About Us</a>
                        <a href="#packages" style={{ textDecoration: 'none' }}>Our Packages</a>
                        <a href="#exploreProducts" style={{ textDecoration: 'none' }}>Our Products</a>
                        <a href="#gamessection" style={{ textDecoration: 'none' }}>Portfolio</a>
                        <a href="#affiliate" style={{ textDecoration: 'none' }}>Affiliate</a>
                    </div>
                </nav>
            </header>
            <div>
                {showPopup && (
                    <>
                        <div className="backdrop"></div>
                        <div className="popup-form">
                            <button className="close-button" onClick={handleCloseClick}>
                                <FaTimes />
                            </button>
                            <h2>Your Basic <span style={{ color: 'red', fontWeight: 'bolder' }}>INFO.</span></h2>

                            <form>
                                <div className="input-group">
                                    <label htmlFor="name"> Name</label>
                                    <input type="text" id="name" name="name" required />
                                    <label htmlFor="name">Twitter Account</label>
                                    <input type="text" id="name" name="name" required />
                                </div>

                                <div className="input-group">
                                    <label htmlFor="email">Email</label>
                                    <input type="email" id="email" name="email" required />
                                    <label htmlFor="name">Prefered designer</label>
                                    <input type="text" id="name" name="name" />
                                </div>
                                <div className="input-group">
                                    <label htmlFor="email">Location</label>
                                    <input type="email" id="email" name="email" required />
                                    <label htmlFor="name">debut date</label>
                                    <input type="date" id="name" name="name" />
                                </div>
                                <button className='btn' type="submit">Submit</button>

                            </form>
                        </div>
                    </>
                )}
            </div>
        </>

    )
}

export default Header