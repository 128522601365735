import react from 'react';
import Homepage from './Pages/Home'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Categories from './Pages/Categories';
import Products from './Pages/products';
import Portfolio from './Pages/Portfolio';
import Packages from './Pages/Packages';
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" exact element={<Homepage />} />
        <Route path="/categories/:categoryName" exact element={<Categories />} />
        <Route path="/products" exact element={<Products />} />
        <Route path="/portfolio" exact element={<Portfolio />} />
        <Route path="/packages" exact element={<Packages />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
